const Outlink = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_262_21)">
      <path
        d="M6.25 0.5C5.83579 0.5 5.5 0.835786 5.5 1.25C5.5 1.66421 5.83579 2 6.25 2H8.93934L4.21967 6.71967C3.92678 7.01256 3.92678 7.48744 4.21967 7.78033C4.51256 8.07322 4.98744 8.07322 5.28033 7.78033L10 3.06066V5.75C10 6.16421 10.3358 6.5 10.75 6.5C11.1642 6.5 11.5 6.16421 11.5 5.75V1.25C11.5 0.835786 11.1642 0.5 10.75 0.5H6.25Z"
        fill="#3889FF"
      />
      <path
        d="M2 5.25C2 4.83579 2.33579 4.5 2.75 4.5H3.75C4.16421 4.5 4.5 4.16421 4.5 3.75C4.5 3.33579 4.16421 3 3.75 3H2.75C1.50736 3 0.5 4.00736 0.5 5.25V9.25C0.5 10.4926 1.50736 11.5 2.75 11.5H6.75C7.99264 11.5 9 10.4926 9 9.25V8.25C9 7.83579 8.66421 7.5 8.25 7.5C7.83579 7.5 7.5 7.83579 7.5 8.25V9.25C7.5 9.66421 7.16421 10 6.75 10H2.75C2.33579 10 2 9.66421 2 9.25V5.25Z"
        fill="#3889FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_262_21">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Outlink
