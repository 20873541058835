const Exclamation = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_201_3655)">
      <path
        d="M10.5 6.25007C11.1904 6.25007 11.75 6.80972 11.75 7.50008L11.75 10.0001C11.75 10.6904 11.1904 11.2501 10.5 11.2501C9.80964 11.2501 9.25 10.6904 9.25 10.0001L9.25002 7.50006C9.25002 6.80971 9.80967 6.25007 10.5 6.25007Z"
        fill="#E9B911"
      />
      <path
        d="M11.75 13.3334C11.75 14.0238 11.1904 14.5834 10.5 14.5834C9.80965 14.5834 9.25 14.0238 9.25 13.3334C9.25 12.643 9.80965 12.0834 10.5 12.0834C11.1904 12.0834 11.75 12.643 11.75 13.3334Z"
        fill="#E9B911"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7652 1.50876C11.5658 0.151704 9.43427 0.151703 8.23485 1.50876C4.96084 5.21302 2.41943 9.55539 0.80759 14.2319C0.210568 15.9641 1.299 17.8095 3.07886 18.1572C7.9577 19.1104 13.0423 19.1104 17.9212 18.1572C19.701 17.8095 20.7895 15.9641 20.1924 14.2319C18.5806 9.55539 16.0392 5.21302 12.7652 1.50876ZM10.1081 3.16438C10.3124 2.93316 10.6876 2.93316 10.892 3.16438C13.9508 6.62522 16.3247 10.6824 17.8289 15.0465C17.9251 15.3255 17.7498 15.6434 17.4418 15.7036C12.8796 16.5949 8.12047 16.5949 3.55821 15.7036C3.25027 15.6434 3.07498 15.3255 3.17114 15.0465C4.67532 10.6824 7.0492 6.62522 10.1081 3.16438Z"
        fill="#E9B911"
      />
    </g>
    <defs>
      <clipPath id="clip0_201_3655">
        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)

export default Exclamation
